import React, { memo, useEffect, useState } from 'react';
import { graphql, Link as GatsbyLink } from 'gatsby';
import { useLocation } from '@reach/router';
import Layout from 'components/layout';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import moment from 'moment';
import Subscribe from '../components/Subscribe';

import {
  PostsWrapperCss,
  PostTitleCss,
  TagsWrapperCss,
  LeftSideCss,
  RightSideCss,
  PostRootCss,
  RightContentCss,
  ContentsWrapperCss,
  AnchorCss,
  ContentsTitleCss,
  AnchorHashCss,
  LikeCounterCss,
  DateCss,
  DateWrapperCss,
  DateTileCss,
  PostFooterCss,
  HeaderWithAnchorCss,
} from './posts.css';
import ChevronRightSvg from '../images/svg/chevron-right.svg';

import Hr from '../components/Hr';
import List from '../components/List';
import {
  Primary,
  Danger,
  Warning,
  Success,
  Info,
  U,
} from '../components/MdxComponents';

import VideoGifImg from '../components/VideoGifImg';

import Heart from '../components/HeartLike';
import useStore from '../store/useStore';
import SEO from '../utils/seo';

const PostTemplate = ({ data }) => {
  const { getArticleInformation, userId } = useStore();
  const location = useLocation();
  const [anchorTags, setAnchorTags] = useState([]);
  const [likes, setLikes] = useState({ articleLikes: 0, userLikes: 0 });

  useEffect(() => {
    // get the likes only if the userId is set yet
    if (userId) {
      getLikes();
    }
  }, [userId]);

  useEffect(() => {
    if (anchorTags && anchorTags.length === 0) {
      getHeadings();
    }
  }, []);

  const getHeadings = () => {
    const { headings } = data.mdx;
    if (headings) {
      const h2Headings = headings.map(el => {
        if (el.depth === 2) {
          return el.value;
        }
      });
      setAnchorTags(h2Headings);
    }
  };

  const getLikes = async () => {
    const slug = location.pathname.replaceAll('/', '');
    const articleInfo = await getArticleInformation(slug, userId);
    setLikes(articleInfo);
  };

  const post = data.mdx;

  const MarkdownLink = ({ href, children, ...rest }) => {
    // TODO Integrate for same domain -> https://zslabs.com/articles/mdx-link-routing-in-gatsby
    if (href.startsWith('/')) {
      return <GatsbyLink data-link-internal to={href} {...rest} />;
    }
    if (!href.startsWith('http')) {
      return <a href={href} {...rest} />; // eslint-disable-line jsx-a11y/anchor-has-content
    }

    return (
      <a
        data-link-external
        href={href}
        target="_blank"
        rel="noopener noreferrer nofollow"
        {...rest}
      >
        {children}
      </a>
    );
  };

  // Customize markdown component
  const mdxComponents = {
    a: function a(props) {
      return <MarkdownLink {...props} />;
    },
    h2: function h2({ children, id }) {
      return (
        <HeaderWithAnchorCss>
          <AnchorHashCss id={id} />
          {children}
        </HeaderWithAnchorCss>
      );
    },

    'ul.li': function ulli({ children }) {
      return (
        <li>
          <span className="icon-wrap">
            <ChevronRightSvg className="icon-chevron-right" />
          </span>
          <span className="ul-children">{children}</span>
        </li>
      );
    },
    'ol.li': function olli({ children }) {
      return (
        <li>
          <span>{children}</span>
        </li>
      );
    },
    hr: function hr() {
      <Hr widthInPercent="100" verticalMargin="0.8rem" />;
    },
    // Use the below components without having to import in *.mdx
    VideoGifImg,
    List,
    Primary,
    Danger,
    Warning,
    Success,
    Info,
    U,
  };
  const allowLike = ['articles'];
  const showLikeButton = allowLike.includes(post.frontmatter.type);
  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.excerpt}
      />
      <PostRootCss>
        <LeftSideCss>
          <PostsWrapperCss>
            <TagsWrapperCss>
              {post.frontmatter.tags &&
                post.frontmatter.tags.map((tag, i) => <p key={i}>{tag}</p>)}
            </TagsWrapperCss>
            <PostTitleCss>{post.frontmatter.title}</PostTitleCss>

            {/* Render mdx */}
            <MDXProvider components={mdxComponents}>
              <MDXRenderer headings={post.headings}>{post.body}</MDXRenderer>
            </MDXProvider>
          </PostsWrapperCss>

          {/* <Subscribe /> */}
          <PostFooterCss>
            <DateWrapperCss>
              <DateTileCss>Last updated:</DateTileCss>
              <DateCss>
                {moment(post.frontmatter.date, 'MM/DD/YYYY').format(
                  'MMM Do, yyyy'
                )}
              </DateCss>
            </DateWrapperCss>
            <LikeCounterCss mobile>
              <Heart version={1} likes={likes} />
            </LikeCounterCss>
          </PostFooterCss>
        </LeftSideCss>
        {showLikeButton && (
          <RightSideCss>
            <RightContentCss>
              <ContentsWrapperCss>
                <TableOfContents anchorTags={anchorTags} />
                <LikeCounterCss>
                  <Heart version={1} likes={likes} />
                </LikeCounterCss>
              </ContentsWrapperCss>
              {/* <Heart version={2} likes={likes} /> */}
            </RightContentCss>
          </RightSideCss>
        )}
      </PostRootCss>
    </Layout>
  );
};

const TableOfContents = memo(function TableMemo({ anchorTags }) {
  if (!anchorTags || anchorTags.length === 0) {
    return null;
  }
  return (
    <>
      <ContentsTitleCss>TABLE OF CONTENTS</ContentsTitleCss>
      {anchorTags.map(tag => {
        if (!tag) return null;
        return (
          <AnchorCss
            key={tag}
            onClick={e => {
              e.preventDefault();
              const element = document.getElementById(
                `${tag.toLowerCase().replaceAll(' ', '-')}`
              );
              element.scrollIntoView();
            }}
            to={`#${tag.toLowerCase().replaceAll(' ', '-')}`}
          >
            {tag}
          </AnchorCss>
        );
      })}
    </>
  );
});

export const postQuery = graphql`
  query BlogPostByPath($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      headings {
        depth
        value
      }
      fields {
        slug
      }
      frontmatter {
        title
        thumbnail
        date(formatString: "MM/DD/YYYY")
        tags
        type
      }
    }
  }
`;

const MemoPostTemplate = memo(PostTemplate);

export default MemoPostTemplate;
