import React from 'react';
import styled from 'styled-components';

const ListWrapperCss = styled.ul`
  margin: 30px 0 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 500px;
  flex-wrap: wrap;

  li {
    margin-right: 30px;
    color: ${props => props.theme.secondary};
    font-size: 20px;
    margin-bottom: 10px !important;
  }
`;

const List = ({ items = [] }) => {
  return (
    <ListWrapperCss>
      {items.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ListWrapperCss>
  );
};

export default List;
