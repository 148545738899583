import styled from 'styled-components';

export const PostRootCss = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 60px 0 0 30px;

  @media (max-width: ${props => props.theme.breakpoints.TABLET + 'px'}) {
    margin: 40px 0 0;
  }
`;
export const PostsWrapperCss = styled.div`
  word-wrap: break-word;

  h2 {
    font-size: 32px;
    color: ${props => props.theme.tertiary};
    margin: 64px 0 32px;
    position: relative;
  }

  h3 {
    font-size: 28px;
    margin: 36px 0 14px;
  }

  h4 {
    font-size: 24px;
    margin: 32px 0 14px;
  }

  h5 {
    font-size: 19px;
    margin-bottom: 32px;
    text-transform: uppercase;
  }

  h6 {
    font-size: 16px;
    margin-bottom: 32px;
  }

  p {
    border: 0px;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.6;
    margin-bottom: 32px;
    padding: 0px;
    vertical-align: baseline;

    code {
      color: ${props => props.theme.primary};
      display: inline-block;
      font-size: 0.9em;
      letter-spacing: -0.5px;
      margin: 1px 0px;
      padding: 2px 6px;
      position: relative;
      z-index: 2;
      &::before {
        content: '';
        position: absolute;
        top: 0px;
        left: -1px;
        right: -1px;
        bottom: 0px;
        opacity: 0.15;
        background: ${props => props.theme.gray700};
        border-radius: 3px;
        z-index: -1;
      }
    }
  }
  em {
    font-family: Sriracha;
    letter-spacing: -0.25px;
    color: ${props => props.theme.secondary};
    font-style: normal;
  }
  /* blockquote {
    padding: 7px 15px;
    margin: 10px 0;

    > p {
      margin: 0 0 7px;
    }

    > blockquote {
      border-left: none;
      font-size: 16px;
      > blockquote {
        font-size: 16px;
      }
    }
  } */

  a {
    color: ${props => props.theme.primary};
    font-weight: 400;
    text-decoration: none;
    /* transition: box-shadow 400ms ease 0s; */
    box-shadow: 0px 0px 0px ${props => props.theme.primary};
    &:hover {
      box-shadow: 0px 2px 0px ${props => props.theme.primary};
    }
  }

  ul {
    font-size: 18px;
    margin-bottom: 32px;
    list-style: none;

    li {
      margin-bottom: 16px;
      font-weight: 300;
      line-height: 1.6;
      display: flex;
      -webkit-box-align: baseline;
      align-items: baseline;
      justify-content: flex-start;

      /* Custom list for ul */
      .icon-wrap {
        svg.icon-chevron-right {
          display: inline-block;
          height: 10px;
          margin-right: 7px;
          width: 10px;
          fill: ${props => props.theme.text};
        }
      }
      span.ul-children {
        width: 100%;
        & > p:first-child {
          display: inline;
        }
      }
    }
  }

  ol {
    font-size: 18px;
    margin: 15px 0 32px;
    list-style: none;

    li {
      font-weight: 300;
      line-height: 1.6;

      margin-bottom: 16px;
      display: flex;
      -webkit-box-align: baseline;
      align-items: baseline;
      justify-content: flex-start;
      span {
        margin-left: 10px;
      }
    }
  }

  pre {
    font-family: inherit;
    margin: 0 0 30px 0;
    padding: 10px 15px;
  }

  img {
    vertical-align: baseline;
    width: 100%;
  }

  .gatsby-resp-image-wrapper {
    margin: 32px auto 48px;
    box-sizing: content-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    align-items: flex-start;
    max-width: 100% !important;
  }

  .code-title-custom {
    position: relative;
    z-index: 10;
    padding: 6px 14px;
    margin-top: 30px;
    display: inline;

    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: ${props => props.theme.syntaxBg};
    color: ${props => props.theme.gray700};
    border-bottom: none;
    font-size: 16px;
  }

  @media (max-width: 500px) {
    padding: 10px;

    ul,
    ol {
      margin-right: 10px;
    }
  }
`;

export const PostTitleCss = styled.h1`
  color: ${props => props.theme.gray1000};
  font-size: 38px;
  line-height: 42px;
  margin: 0 0 80px;
  text-align: center;

  @media (max-width: ${props => props.theme.breakpoints.TABLET + 'px'}) {
    font-size: 38px;
  }
`;

export const TagsWrapperCss = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  p {
    font-size: 18px;
    color: ${props => props.theme.primary};
    margin-bottom: 10px;
    font-weight: 400;
  }
  p + p {
    margin-left: 10px;
  }
`;

export const RightSideCss = styled.div`
  width: 30%;

  @media (max-width: ${props => props.theme.breakpoints.TABLET + 'px'}) {
    display: none;
  }
`;

export const LeftSideCss = styled.div`
  width: 70%;
  @media (max-width: ${props => props.theme.breakpoints.TABLET + 'px'}) {
    width: 100%;
  }
`;

export const RightContentCss = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  padding: 0 15px 0 60px;
  position: sticky;
  top: 100px;
`;

export const ContentsWrapperCss = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 10px;
`;
export const ContentsTitleCss = styled.h3`
  color: ${props => props.theme.gray1000};
  font-size: 16px;
  letter-spacing: 2px;
  margin-bottom: 16px;
  text-transform: uppercase;
`;

export const AnchorCss = styled.a`
  color: ${props => props.theme.gray1000};
  cursor: pointer;
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 10px;
  opacity: 0.7;
  text-decoration: none;
  transition: opacity 500ms ease 0s;
  &:hover {
    opacity: 1;
  }
`;

export const AnchorHashCss = styled.div`
  position: absolute;
  top: -100px;
  pointer-events: none;
  z-index: -10;
  opacity: 0;
`;

export const LikeCounterCss = styled.div`
  display: flex;
  align-self: flex-start;
  margin-left: 15px;
  margin-top: 20px;

  display: ${props => (props.mobile ? 'none' : 'block')};

  @media (max-width: ${props => props.theme.breakpoints.TABLET + 'px'}) {
    display: ${props => (props.mobile ? 'block' : 'none')};
    margin: 0;
  }
`;

export const DateWrapperCss = styled.div``;
export const DateTileCss = styled.p`
  font-size: 14px;
  color: ${props => props.theme.gray500};
  text-transform: uppercase;
  margin-bottom: 7px;
  font-weight: 500;
  letter-spacing: 0.8px;
`;

export const DateCss = styled.p`
  color: ${props => props.theme.text};
  font-size: 20px;
  font-weight: 300;
`;

export const PostFooterCss = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0;
`;

export const HeaderWithAnchorCss = styled.h2`
  &:hover {
    svg {
      /* visibility: none; */
      // Disable the anchor tags for now
      visibility: none;
      opacity: 0;
      pointer-events: none;
    }
  }
  a {
    box-shadow: none !important;
  }
  svg {
    width: 24px;
    height: 24px;
    opacity: 0.8;

    path {
      fill: ${props => props.theme.tertiary};
    }
  }
`;
