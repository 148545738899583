import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

const WrapperCss = styled.div`
  margin-bottom: 30px;
  video,
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const VideoGifImg = ({ type = 'img', path = '', ...props }) => {
  const [videoWrapper, videoView] = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (videoWrapper.current) {
      if (videoView) {
        videoWrapper.current.play();
      } else {
        videoWrapper.current.pause();
        videoWrapper.current.currentTime = 0;
      }
    }
  }, [videoView]);

  if (type === 'video') {
    return (
      <WrapperCss>
        {/* eslint-disable-next-line */}
        <video
          ref={videoWrapper}
          src={require(`../../images/${path}`)}
          controls={false}
          crossOrigin="true"
          autoPlay="false"
          loop
          playsInline
          muted
          {...props}
        />
      </WrapperCss>
    );
  }
  return (
    <WrapperCss>
      <img
        src={require(`../../images/${path}`)}
        alt="application 1"
        {...props}
      />
    </WrapperCss>
  );
};

export default VideoGifImg;
