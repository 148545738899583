import React from 'react';
import styled from 'styled-components';

export const CustomUnderlineCss = styled.span`
  position: relative;
  border-bottom-width: 2px;
  border-bottom-style: solid;
`;

const Underline = ({ children }) => {
  return <CustomUnderlineCss>{children}</CustomUnderlineCss>;
};

export default Underline;
