import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
// Icons
import Warning from '../../../images/svg/warning.svg';
import Success from '../../../images/svg/success.svg';
import Info from '../../../images/svg/info.svg';
import Stop from '../../../images/svg/stop.svg';

const TextBlock = ({ theme, children, padding = '2rem 1.5rem' }) => {
  let iconElement;

  switch (theme) {
    case 'success':
      iconElement = <Success />;
      break;
    case 'warning':
      iconElement = <Warning />;
      break;
    case 'danger':
      iconElement = <Stop />;
      break;
    // For 'info' & 'primary'
    default:
      iconElement = <Info />;
  }

  return (
    <StyledTextBlock themeObject={theme} padding={padding}>
      <StyledIconWrap themeObject={theme}>{iconElement}</StyledIconWrap>
      {children}
    </StyledTextBlock>
  );
};

export default TextBlock;

// Check props
TextBlock.propTypes = {
  theme: PropTypes.string.isRequired,
};

const StyledTextBlock = styled.div`
  border-left-style: solid;
  border-left-width: 3px;
  border-radius: 6px 6px 6px 3px;
  font-size: 17px;
  line-height: 1.5;
  font-weight: 300;
  margin-bottom: 64px;
  margin-top: 48px;
  padding: 24px 32px;
  position: relative;
  transition: background-color 350ms ease 0s, border-color 350ms ease 0s;

  /* Default for primary */
  border-color: ${props => props.theme.gray200};
  background: ${props => props.theme.background};
  /* Based on chosen theme */
  border-color: ${props => props.theme.textBlockBorder[props.themeObject]};
  background: ${props => props.theme.textBlock[props.themeObject]};
  & > *:last-child {
    margin-bottom: 0;
  }
  & > *:nth-child(2) {
    // First child is the icon
    margin-top: 0 !important;
  }
`;

const StyledIconWrap = styled.div`
  background: ${props => props.theme.background};
  border-radius: 50%;
  display: block;
  left: 0;
  padding: 7px;
  position: absolute;
  top: 0;
  transform: translate(-50%, -50%);
  transition: background-color 350ms ease 0s;
  svg {
    transition: fill 350ms ease 0s;
    width: 30px;
    height: 30px;
    /* Default */
    fill: ${props => props.theme.gray200};
    /* Theme */
    fill: ${props => props.theme.textBlockBorder[props.themeObject]};
  }
`;
