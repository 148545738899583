import styled, { css } from 'styled-components';

export const HeartWrapperCss = styled.div`
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  svg {
    width: 60px !important;
    height: 60px !important;
    margin-right: 15px;
    margin-left: 0px;
  }

  @media (max-width: ${props => props.theme.breakpoints.TABLET + 'px'}) {
    flex-direction: row-reverse;
    svg {
      width: 60px !important;
      height: 60px !important;
      margin-right: 0px;
      margin-left: 15px;
    }
  }
  .heart-path {
    stroke: ${props => props.theme.gray100};
    fill: ${props => props.theme.gray400};
    transform-origin: 50% 50%;
    transition: all 200ms ease 0s;
  }
  ${props =>
    props.activePieces.length === props.totalPieces &&
    css`
      ${props.animate && 'animation: heartbeat 2s'};
      .heart-path {
        stroke: ${props => props.theme.red};
      }
    `}
  ${props =>
    props.activePieces.map(index => {
      return css`
        .puzzle-${index} {
          fill: ${props => props.theme.red};
        }
      `;
    })}

@keyframes heartbeat {
    0% {
      transform: scale(1);
    }
    25% {
      transform: scale(0.97);
    }
    35% {
      transform: scale(0.9);
    }
    45% {
      transform: scale(1.1);
    }
    55% {
      transform: scale(0.9);
    }
    65% {
      transform: scale(1.1);
    }
    75% {
      transform: scale(1.03);
    }
    100% {
      transform: scale(1);
    }
  }
`;
