import styled, { css } from 'styled-components';

export const SubscribeWrapperCss = styled.div`
  background: #ffffff66;
  padding: 10px;
  border-radius: 5px;
`;

export const InputCss = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 30%;
`;
export const EmailInputCss = styled.input`
  background: none;
  border: none;
  border-bottom: 1px solid black;
`;
export const TitleCss = styled.h2`
  font-size: 18px;
  margin-bottom: 15px;
`;
export const SubmitCss = styled.button`
  background: white;
  border-radius: 20px;
  border: 2px solid ${props => props.theme.primary};
  color: ${props => props.theme.primary};
  cursor: pointer;
  font-size: 15px;
  letter-spacing: 0.5px;
  padding: 6px 5px 5px;
  transition: all 0.2s ease-in-out;
  width: 120px;

  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: scale(1);
    }
    40% {
      transform: scale(1.2);
    }
    60% {
      transform: scale(1.1);
    }
  }
  span {
    display: block;
    transition: width 0.4s ease-in-out;
  }
  &:hover {
    color: white;
    background: ${props => props.theme.primary};
  }
  &:focus {
    outline: none;
    span {
      animation: bounce 0.5s ease-in-out;
    }
  }
  ${props =>
    props.loading &&
    css`
      span {
        width: 0px;
        opacity: 0;
      }
    `}
`;
export const ErrorCss = styled.h4`
  color: red;
`;
