import React, { useState, memo } from 'react';

import { HeartWrapperCss } from './index.css';
import HeartSvg from '../../images/svg/heart.svg';
import Heart2Svg from '../../images/svg/heart2.svg';
import { useEffect } from 'react';
import { useLocation } from '@reach/router';
import useStore from '../../store/useStore';
const array15 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
const array8 = [1, 2, 3, 4, 5, 6, 7, 8];

const Heart = ({ version = 1, likes }) => {
  const location = useLocation();
  const { likeArticle } = useStore();

  const [articleLikesCnt, setArticleLikesCnt] = useState(
    likes ? likes.articleLikes : 0
  );

  const [animate, setAnimate] = useState(false);
  const [activePieces, setActivePieces] = useState([]);
  const [piecesLeft, setPiecesLeft] = useState(
    version === 1 ? array15 : array8
  );

  const setInitialLikes = () => {
    // Set the initial likes
    const numberOfLikes = likes ? likes.userLikes : 0;

    const piecesLeftCopy = [...array15];
    const roll = piecesLeftCopy.splice(0, numberOfLikes);

    setPiecesLeft(piecesLeftCopy);
    setActivePieces([...roll]);
  };

  useEffect(() => {
    if (likes) {
      // If it updates -> get new values
      setArticleLikesCnt(likes.articleLikes);
      setInitialLikes();
    }
  }, [likes]);

  useEffect(() => {
    setInitialLikes();
  }, []);

  const activatePiece = () => {
    setAnimate(true);
    // If we still have elements not selected
    if (piecesLeft.length > 0) {
      const slug = location.pathname.replaceAll('/', '');
      likeArticle(slug);
      setArticleLikesCnt(e => e + 1);
    }

    const piecesLeftCopy = [...piecesLeft];
    const num = Math.floor(Math.random() * piecesLeftCopy.length);
    const roll = piecesLeftCopy.splice(num, 1);

    setPiecesLeft(piecesLeftCopy);
    setActivePieces([...activePieces, ...roll]);
  };
  return (
    <HeartWrapperCss
      onClick={activatePiece}
      onMouseDownCapture={e => e.preventDefault()} // prevent text selection
      onKeyDown={() => {}}
      role="button"
      tabIndex={0}
      animate={animate}
      activePieces={activePieces}
      totalPieces={version === 1 ? 15 : 8}
    >
      {version === 1 ? <HeartSvg tiles={15} /> : <Heart2Svg tiles={8} />}{' '}
      {articleLikesCnt}
    </HeartWrapperCss>
  );
};

export default Heart;
